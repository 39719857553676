import React from "react";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import './GallerySlider.css';

SwiperCore.use([Navigation, Pagination]);

const GallerySlider = () => {
  return (
    <section id="projects" className="gallery-section">
      <h2>Projects</h2>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        navigation
        pagination={{ clickable: true }}
        className="gallery-images"
      >
        <SwiperSlide>
          <a href="https://victogemma.com" target="noopener noreferrer"><h2>Victoria gemma</h2><h2>UI</h2><img src={require("../images/VictoriaLogo.PNG")} alt="Victoria Gemma Shop" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://dosamigosmoving.com" target="noopener noreferrer"><h2>Dos amigos moving</h2><h2>UI</h2><h2>BE</h2><img src={require("../images/DosAmigos.jpg")} alt="Dos amigos moving" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.goshenretirementhomes.com" target="noopener noreferrer"><h2>Goshen retirement homes</h2><h2>UI</h2><img src={require("../images/GOSHEN.png")} alt="Goshen retirement homes" /></a>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default GallerySlider;